<template>
  <div id="app">
    <div v-if="!isLoading">
      <router-view/>
    </div>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from '@/components/loading/Loading'
import { Auth, User, Store } from '@/api/api'

export default {
  name: 'App',
  components: { Loading },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      isAdmin: 'auth/isAdmin',
      isVendor: 'auth/isVendor'
    })
  },
  mounted () {
    this.checkAuth()
  },
  methods: {
    async checkAuth () {
      const token = window.localStorage.getItem('loginToken')

      if (token) {
        Auth.setToken(token)

        try {
          const { data } = await User.me()

          const store = await Store.getMine()

          this.$store.dispatch('auth/login', {
            token,
            user: {
              ...data,
              store: store.data
            }
          })
        } catch (e) {
          // todo. to skevos I guess the issue is here exception are not handled and redirect user to login page?
        } finally {
          window.setTimeout(() => {
            this.isLoading = false
          }, 250)
        }
      } else {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import "styles/main";
</style>
