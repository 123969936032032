<template>
  <div class="error-page flex justify-center mg-t-50 pd-t-50">
    <div class="box w-500 flex align-center direction-column">
      <h1>404</h1>
      <h2><span>P</span>age <span>N</span>ot <span>F</span>ound</h2>
      <router-link :to="locationPath" class="btn btn--red">{{textBtn}}</router-link>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Error',
  data () {
    return {
      locationPath: '/',
      textBtn: 'Back to home'
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      isAdmin: 'auth/isAdmin',
      isOperator: 'auth/isOperator',
      isVendor: 'auth/isVendor'
    })
  },
  mounted () {
    if (this.isAuthed) {
      this.textBtn = 'Back to Dashboard'

      if (this.isAdmin) {
        this.locationPath = '/dashboard/admin/products'
      } else if (this.isOperator) {
        this.locationPath = '/dashboard/admin/products'
      } else {
        this.locationPath = '/dashboard/orders'
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
