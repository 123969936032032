import asyncComponent from '@/router/asyncComponent'
import Error from '@/pages/error/error'

export default [
  {
    path: '/',
    name: 'Landing',
    component: asyncComponent({
      loader: () => import('@/pages/landing/landing')
    })
  },
  {
    path: '/info',
    name: 'Cms',
    component: asyncComponent({
      loader: () => import('@/pages/cms/cms')
    })
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: asyncComponent({
      loader: () => import('@/pages/privacy/privacy')
    })
  },
  {
    path: '/terms',
    name: 'Terms',
    component: asyncComponent({
      loader: () => import('@/pages/terms/terms')
    })
  },
  {
    path: '/faq',
    name: 'FAQs',
    component: asyncComponent({
      loader: () => import('@/pages/faq/faq')
    })
  },
  {
    path: '/join',
    name: 'Entry',
    component: asyncComponent({
      loader: () => import('@/pages/auth/entry')
    })
  },
  {
    path: '/login',
    name: 'Login',
    component: asyncComponent({
      loader: () => import('@/pages/auth/login')
    })
  },
  {
    path: '/register',
    name: 'Register',
    component: asyncComponent({
      loader: () => import('@/pages/auth/register')
    })
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: asyncComponent({
      loader: () => import('@/pages/dashboard/dashboard'),
      isPrivate: true
    }),
    children: [
      {
        path: 'profile/:id',
        name: 'Profile',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/profile/profile'),
          isPrivate: true,
          isVendor: true
        })
      },
      {
        path: 'profile/:id/edit',
        name: 'ProfileEdit',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/profile/profileEdit'),
          isPrivate: true,
          isVendor: true
        })
      },
      {
        path: 'products',
        name: 'ProductList',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/products/list/list'),
          isPrivate: true,
          isVendor: true
        })
      },
      {
        path: 'admin/products',
        name: 'ProductListAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/products/list/list'),
          isPrivate: true
        })
      },
      {
        path: 'products/:id',
        name: 'ProductDetails',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/products/details/details'),
          isPrivate: true,
          isVendor: true
        })
      },
      {
        path: 'products/:id/edit',
        name: 'ProductDetailsEdit',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/products/details/detailsEdit'),
          isPrivate: true,
          isVendor: true
        })
      },
      {
        path: 'admin/products/:id',
        name: 'ProductDetailsAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/products/details/details'),
          isPrivate: true,
          isAdmin: true,
          isOperator: true
        })
      },
      {
        path: 'orders',
        name: 'OrderList',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/orders/list/list'),
          isPrivate: true,
          isVendor: true
        })
      },
      {
        path: 'orders/:id',
        name: 'OrderDetails',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/orders/details/details'),
          isPrivate: true,
          isVendor: true
        })
      },
      {
        path: 'admin/orders',
        name: 'OrderListAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/orders/list/list'),
          isPrivate: true,
          isAdmin: true,
          isOperator: true
        })
      },
      {
        path: 'admin/orders/:id',
        name: 'OrderDetailsAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/orders/details/details'),
          isPrivate: true,
          isAdmin: true,
          isOperator: true
        })
      },
      {
        path: 'admin/merchants',
        name: 'MerchantsListAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/merchants/list/list'),
          isPrivate: true,
          isAdmin: true,
          isOperator: true
        })
      },
      {
        path: 'admin/merchants/:id',
        name: 'MerchantsDetailsAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/merchants/details/details'),
          isPrivate: true,
          isAdmin: true,
          isOperator: true
        })
      },
      {
        path: 'admin/merchants/:id/edit',
        name: 'MerchantsProfileEdit',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/merchants/details/profileEdit'),
          isPrivate: true,
          isAdmin: true,
          isOperator: true
        })
      },
      {
        path: 'admin/settings/wide',
        name: 'WideSettingsAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/settings/general'),
          isPrivate: true,
          isAdmin: true
        })
      },
      {
        path: 'admin/settings/banner',
        name: 'BannerSettingsAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/settings/banner'),
          isPrivate: true,
          isAdmin: true,
          isOperator: true
        })
      },
      {
        path: 'admin/settings/push',
        name: 'PushSettingsAdmin',
        component: asyncComponent({
          loader: () => import('@/pages/dashboard/admin/settings/push'),
          isPrivate: true,
          isAdmin: true
        })
      },
      {
        path: '/:pathMatch(.*)*',
        component: Error
      }
    ]
  }
]
