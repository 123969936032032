import { defineAsyncComponent } from 'vue'
import Error from '@/pages/error/error'
import store from '@/store/index'

export default (options) => {
  if (options.isPrivate && !store.getters['auth/isAuthed']) {
    return Error
  } else if (options.isAdmin && !options.isOperator && !store.getters['auth/isAdmin']) {
    return Error
  } else if (options.isOperator && !store.getters['auth/isOperator'] && !store.getters['auth/isAdmin']) {
    return Error
  } else if (options.isVendor && !store.getters['auth/isVendor']) {
    return Error
  } else {
    return defineAsyncComponent({
      loader: options.loader
    })
  }
}
