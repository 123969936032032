import { createI18n } from 'vue-i18n'

const messages = {
  en: require('./locales/en.json'),
  'zh-hk': require('./locales/zh-HK.json')
}

export default createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  silentTranslationWarn: true,
  messages
})
