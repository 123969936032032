export const Auth = {
  login: (email, password) => {
    return window.axios.post('auth/login', {
      email,
      password
    })
  },
  logout: () => {
    return window.axios.post('auth/logout')
  },
  setToken: (token) => {
    window.axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    }
  },
  verify: (email, code) => {
    return window.axios.post('auth/code/check', {
      email,
      code
    })
  },
  sendReset: (email) => {
    return window.axios.post('password/forgot', {
      email
    })
  },
  passReset: (email, password, code) => {
    return window.axios.post('password/reset', {
      email,
      password,
      code
    })
  },
  register: (email, password) => {
    return window.axios.post('auth/reg/merchant', {
      email,
      password
    })
  }
}

export const User = {
  me: () => {
    return window.axios.get('user')
  },
  getAll: (options) => {
    return window.axios.get('admin/users')
  },
  updateUser: (id, data) => {
    return window.axios.put(`user/${id}`, {
      ...data
    })
  }
}

export const Settings = {
  get: () => {
    return window.axios.get('settings')
  },
  update: (id, data) => {
    return window.axios.post(`admin/settings/${id}`, {
      ...data
    })
  },
  getRegions: () => {
    return window.axios.get('regions')
  },
  getServices: () => {
    return window.axios.get('services')
  },
  sendPush: (data) => {
    return window.axios.post('admin/push', data)
  }
}

export const Upload = {
  image: (file) => {
    const body = new FormData()
    body.append('file', file)

    return window.axios.request({
      url: 'upload/image',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      },
      data: body
    })
  },
  file: (file) => {
    const body = new FormData()
    body.append('file', file)

    return window.axios.request({
      url: 'upload/file',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      },
      data: body
    })
  }
}

export const Store = {
  getMine: () => {
    return window.axios.get('merchant/store')
  },
  create: (data) => {
    return window.axios.post('merchant/store', data)
  },
  updateOne: (store, data) => {
    return window.axios.put(`merchant/store/${store}`, data)
  },
  getOne: (store) => {
    return window.axios.get(`stores/${store}`)
  }
}

export const Product = {
  adminAll: (options) => {
    return window.axios.get('admin/products', {
      params: {
        ...options
      }
    })
  },
  merchantAll: (store, options) => {
    return window.axios.get(`merchant/store/${store}/product`, {
      params: {
        ...options
      }
    })
  },
  toggleProducts: (store, publish, ids) => {
    return window.axios.post(`merchant/store/${store}/product/toggle`, {
      publish,
      ids
    })
  },
  clone: (store, ids) => {
    return window.axios.post(`merchant/store/${store}/product/clone`, {
      ids
    })
  },
  merchantDelete: (store, product) => {
    return window.axios.delete(`merchant/store/${store}/product/${product}`, {})
  },
  updateOne: (store, product, data) => {
    return window.axios.put(`merchant/store/${store}/product/${product}`, data)
  },
  createOne: (store, data) => {
    return window.axios.post(`merchant/store/${store}/product`, data)
  },
  getOne: (store, product) => {
    return window.axios.get(`merchant/store/${store}/product/${product}`)
  },
  merchantComment: (store, product, comment) => {
    return window.axios.post(`merchant/store/${store}/product/${product}/comment`, {
      comment
    })
  }
}

export const Merchant = {
  adminAll: (options) => {
    return window.axios.get('admin/merchants', {
      params: {
        ...options
      }
    })
  }
}

export const Orders = {
  adminAll: (options) => {
    return window.axios.get('admin/orders', {
      params: {
        ...options
      }
    })
  },
  merchantAll: (options) => {
    return window.axios.get('merchant/orders', {
      params: {
        ...options
      }
    })
  },
  adminComment: (id, comment) => {
    return window.axios.post(`admin/order/${id}/comment`, {
      comment
    })
  },
  merchantComment: (id, comment) => {
    return window.axios.post(`merchant/order/${id}/comment`, {
      comment
    })
  },
  statusChange: (id, status) => {
    return window.axios.post(`merchant/order/${id}/status`, {
      status
    })
  },
  getOne: (id, itemId) => {
    return window.axios.get(`merchant/order/${id}`, {
      params: {
        item_id: itemId
      }
    })
  },
  refund: (id, price, reason) => {
    return window.axios.post(`merchant/order/${id}/refund`, {
      price,
      reason
    })
  }
}
