import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueFinalModal from 'vue-final-modal'
import Toaster from '@meforma/vue-toaster'
import axios from 'axios'
import moment from 'moment-timezone'
import { endpoint } from '@/config/config'
import i18n from './config/i18n/i18n'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

window.axios = axios.create({
  baseURL: endpoint,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

window.axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    const pages = ['login', 'auth/reg/merchant', 'user', 'password/reset', 'password/forgot', 'auth/code/check', 'auth/logout']
    if (new RegExp(pages.join('|') + '$').test(error.response.config.url)) {
      return Promise.reject(error)
    } else {
      localStorage.clear()
      sessionStorage.clear()
      window.location = '/login'
    }
  }

  return Promise.reject(error)
})

moment.tz.setDefault('GMT')

const appVue = createApp(App)

appVue.config.globalProperties.$filters = {
  formatDate (value) {
    return value ? moment.tz(value, 'UTC').tz('Asia/Hong_Kong').format('D MMMM YYYY') : ''
  },
  formatDateOnly (value) {
    return value ? moment(value).format('D MMMM YYYY') : ''
  },
  formatTimestamp (value) {
    const date = moment.tz(value, 'UTC').tz('Asia/Hong_Kong')
    return value ? date.format('hh:mm A') + ' HK Time ' + date.format('D MMMM YYYY') : ''
  },
  formatPrice (price) {
    if (price === undefined || price === null) {
      price = 0
    }

    price = parseFloat(price)

    return 'HKD $' + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }
}

Sentry.init({
  appVue,
  dsn: 'https://d9ca6bbfd418465497b65c6753f3f3b7@o455698.ingest.sentry.io/5702804',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})
Sentry.attachErrorHandler(appVue, { logErrors: true })

appVue.use(i18n)
appVue.use(Toaster)
appVue.use(router)
appVue.use(store)
appVue.use(VueFinalModal())
appVue.mount('#app')
