// export const endpoint = 'http://127.0.0.1:8000/api/'
// export const endpoint = 'https://93b45ac1985dbfb1.api.lifes.hk/api/'

export const endpoint = process.env.VUE_APP_API_DOMAIN

export const productType = {
  1: 'data.type.offer',
  2: 'data.type.promotion',
  3: 'data.type.points',
  4: 'data.type.eshop'
}

export const currencies = [
  'HKD'
]

export const productTypeOptions = [
  {
    value: 1,
    label: 'data.type.offer'
  },
  {
    value: 2,
    label: 'data.type.promotion'
  },
  {
    value: 3,
    label: 'data.type.points'
  },
  {
    value: 4,
    label: 'data.type.eshop'
  }
]

export const productStatus = {
  1: 'data.status.unapproved',
  2: 'data.status.unpublished',
  3: 'data.status.published',
  4: 'data.status.pending'
}

export const promoTypes = {
  1: 'data.promo.buy1get1',
  2: 'data.promo.buy1get2',
  3: 'data.promo.buy2get3',
  4: 'data.promo.xperoff',
  5: 'data.promo.xoff'
}

export const promoTypeOptions = [
  {
    value: 1,
    label: 'data.promo.buy1get1'
  },
  {
    value: 2,
    label: 'data.promo.buy1get2'
  },
  {
    value: 3,
    label: 'data.promo.buy2get3'
  },
  {
    value: 4,
    label: 'data.promo.xperoff'
  },
  {
    value: 5,
    label: 'data.promo.xoff'
  }
]

export const businessTypes = [
  {
    value: 1,
    label: 'data.businessTypes.corp'
  },
  {
    value: 2,
    label: 'data.businessTypes.part'
  },
  {
    value: 3,
    label: 'data.businessTypes.sole'
  },
  {
    value: 4,
    label: 'data.businessTypes.indi'
  },
  {
    value: 5,
    label: 'data.businessTypes.nonp'
  }
]

export const businessTypeText = {
  1: 'data.businessTypes.corp',
  2: 'data.businessTypes.part',
  3: 'data.businessTypes.sole',
  4: 'data.businessTypes.indi',
  5: 'data.businessTypes.nonp'
}

export const types = {
  business: {
    CORPORATION: 1,
    PARTNERSHIP: 2,
    SOLEPROPRIETORSHIP: 3,
    INDIVIDUAL: 4,
    NONPROFIT: 5
  }
}
