<template>
  <div class="loading">
    <div class="spinner">
      <div class="double-bounce1"/>
      <div class="double-bounce2"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style scoped>

</style>
