export default {
  namespaced: true,
  state: {
    data: null,
    loading: false
  },
  getters: {
    isAuthed: (state) => {
      return !!state.data
    },
    me: (state) => {
      return state.data || {}
    },
    loading: (state) => {
      return state.loading
    },
    isAdmin: (state) => {
      return state.data && state.data.type === 3
    },
    isVendor: (state) => {
      return state.data && state.data.type === 2
    },
    isOperator: (state) => {
      return state.data && state.data.type === 4
    },
    isVerified: (state) => {
      return state.data && state.data.type === 2 && state.data.store && state.data.store.status === 2
    }
  },
  mutations: {
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    setData: (state, data) => {
      state.data = data
    },
    clearData: (state) => {
      state.data = null
    },
    updateData: (state, data) => {
      state.data = {
        ...state.data,
        ...data
      }
    }
  },
  actions: {
    login: ({ commit }, { token, user }) => {
      commit('startLoading')
      window.localStorage.setItem('loginToken', token)
      commit('setData', user)
      commit('stopLoading')
    },
    register: ({ commit }, data) => {
      commit('startLoading')
      // do login
      setTimeout(() => {
        commit('setData', {})
        commit('stopLoading')
      }, 2000)
    },
    logout: ({ commit }) => {
      commit('startLoading')
      window.localStorage.removeItem('loginToken')
      commit('clearData')
      commit('stopLoading')
    }
  }
}
